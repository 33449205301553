<template>
  <vbt-content-box
    :no-content="!items.length"
    :loading-error="loadingError"
    :loading="loading"
    title="History"
  >
    <vbt-table
      :headers="headers"
      :items="items"
      show-paginator
    >
      <template #processed="{ item: { processed: _dateForFormatting } }">
        {{ formatDisplayDate(_dateForFormatting) }}
      </template>

      <template #state="{ item: { state } }">
        <dsb-entity-badge :entity="state" />
      </template>
    </vbt-table>
  </vbt-content-box>
</template>

<script>
import { formatDisplayDate } from '@helpers';

const headers = Object.freeze([
  { text: 'Processed Date', value: 'processed' },
  { text: 'State', value: 'state' },
  { text: 'Details', value: 'details' },
]);

export default {
  name: 'DsbOrderItemHistory',

  data() {
    return {
      headers,
    };
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    loadingError: {
      type: [Boolean, String, Object],
      default: false,
    },
    loading: Boolean,
  },

  methods: {
    formatDisplayDate,
  },
};
</script>
