<template>
  <vbt-content-box
    :loading-error="loadingError"
    :loading="loading"
    title="Tags"
  >
    <v-flex
      class="mt-n3"
      sm12
    >
      <router-link
        v-for="tag in tags"
        :key="tag"
        :to="{
          name: 'orderItems',
          query: {
            suffix,
            pageNum: '1',
            tags: [tag]
          },
        }"
        target="_blank"
      >
        <v-chip
          color="success"
          class="mx-1 mt-3"
          style="cursor: pointer;"
        >
          #{{ tag }}
        </v-chip>
      </router-link>
    </v-flex>
  </vbt-content-box>
</template>

<script>
import { mapState } from 'vuex';

import { AppLocationsNamespace } from '@store/types';

export default {
  name: 'DsbOrderItemTags',

  props: {
    tags: {
      type: Array,
      required: true,
    },
    loadingError: {
      type: [Boolean, String, Object],
      default: false,
    },
    loading: Boolean,
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),
  },
};
</script>
