<template>
  <vbt-content-box
    :loading-error="loadingError"
    :loading="loading"
    title="Details"
  >
    <template #toolbar>
      <dsb-order-items-sync
        v-if="hadAdminRole"
        :ids="item.orderId"
        title="Sync order item with ProFIT"
        v-on="$listeners"
      />
    </template>

    <v-layout align-center>
      <v-flex sm4>
        <strong>
          Order Item ID:
        </strong>
        {{ item.orderItemId }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Product Type:
        </strong>
        {{ item.productType || '-' }}
      </v-flex>

      <v-flex sm4>
        <v-layout align-center>
          <dsb-entity-badge :entity="item.state">
            State:
            <strong class="ml-1">
              {{ item.state }}
            </strong>
          </dsb-entity-badge>
          <dsb-entity-badge
            color="success"
            class="ml-3"
          >
            Contract:
            <strong
              v-if="item.contract"
              class="ml-1"
            >
              {{ item.contract }}
            </strong>
            <span v-else>
              -
            </span>
          </dsb-entity-badge>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-divider class="my-2" />

    <v-layout align-center>
      <v-flex sm4>
        <strong>
          Order ID:
        </strong>
        <a
          :href="item.sourceUrl"
          target="_blank"
          class="info--text"
        >
          {{ item.orderId }}
        </a>
      </v-flex>

      <v-flex sm4>
        <strong>
          Added To DSB:
        </strong>
        {{ formatDisplayDate(item.created) }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Truck Date:
        </strong>
        {{ formatDisplayDate(item.truckDate) }}
      </v-flex>
    </v-layout>

    <v-divider class="my-2" />

    <v-layout align-center>
      <v-flex sm4>
        <strong>
          Laminating:
        </strong>
        {{ item.laminating || '-' }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Variable UV:
        </strong>
        {{ item.variableUv || '-' }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Cover:
        </strong>
        {{ item.cover || '-' }}
      </v-flex>
    </v-layout>

    <v-divider class="my-2" />

    <v-layout align-center>
      <v-flex sm4>
        <strong>
          Foil Color Front:
        </strong>
        {{ item.foilColorFront || '-'  }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Foil Color Back:
        </strong>
        {{ item.foilColorBack || '-'  }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Rounded Corners:
        </strong>
        {{ roundedCornersText  }}
      </v-flex>
    </v-layout>
  </vbt-content-box>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatDisplayDate } from '@helpers';

import { AuthNamespace } from '@store/types';

import DsbOrderItemsSync from './DsbOrderItemsSync.vue';

export default {
  name: 'DsbOrderItemDetails',

  components: {
    DsbOrderItemsSync,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    loadingError: {
      type: [Boolean, String, Object],
      default: false,
    },
    loading: Boolean,
  },

  computed: {
    ...mapGetters(AuthNamespace, ['hadAdminRole']),

    roundedCornersText() {
      if (typeof this.item.roundedCorners === 'boolean') {
        return this.item.roundedCorners ? 'Yes' : 'No';
      }

      return '-';
    },
  },

  methods: {
    formatDisplayDate,
  },
};
</script>
