<template>
  <vbt-content-box
    :no-content="!items.length"
    :loading-error="loadingError"
    :loading="loading"
    title="Signatures"
  >
    <vbt-table
      :headers="headers"
      :items="items"
      show-paginator
    />
  </vbt-content-box>
</template>

<script>
const headers = Object.freeze([
  { text: 'Number', value: 'number' },
  { text: 'Type', value: 'signatureType' },
  { text: 'Substrate', value: 'substrate' },
  { text: 'Front', value: 'front' },
  { text: 'Back', value: 'back' },
  { text: 'Quantity', value: 'quantity' },
]);

export default {
  name: 'DsbOrderItemSignatures',

  data() {
    return {
      headers,
    };
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    loadingError: {
      type: [Boolean, String, Object],
      default: false,
    },
    loading: Boolean,
  },
};
</script>
