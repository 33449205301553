var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vbt-content-box',{attrs:{"loading-error":_vm.loadingError,"loading":_vm.loading,"title":"Tags"}},[_c('v-flex',{staticClass:"mt-n3",attrs:{"sm12":""}},_vm._l((_vm.tags),function(tag){return _c('router-link',{key:tag,attrs:{"to":{
        name: 'orderItems',
        query: {
          suffix: _vm.suffix,
          pageNum: '1',
          tags: [tag]
        },
      },"target":"_blank"}},[_c('v-chip',{staticClass:"mx-1 mt-3",staticStyle:{"cursor":"pointer"},attrs:{"color":"success"}},[_vm._v(" #"+_vm._s(tag)+" ")])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }