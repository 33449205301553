<template>
  <vbt-content-box
    :loading-error="loadingError"
    :loading="loading"
    title="Types"
  >
    <v-layout
      v-for="(t, i) in types"
      :key="i"
      column
    >
      <v-layout>
        <v-flex sm4>
          <v-layout column>
            <v-layout>
              <strong class="mr-1">
                Name:
              </strong>
              {{ t.name }}
            </v-layout>

            <v-layout>
              <strong class="mr-1">
                UV Front:
              </strong>
              {{ t.uvFront }}
            </v-layout>

            <v-layout>
              <strong class="mr-1">
                UV Back:
              </strong>
              {{ t.uvBack }}
            </v-layout>
          </v-layout>
        </v-flex>

        <v-divider
          vertical
          class="mx-4"
        />

        <v-flex sm3>
          <vbt-table
            :headers="machinesHeaders"
            :items="t.machines.map((name) => ({ name }))"
            table-name="Machines"
            show-paginator
          />
        </v-flex>

        <v-divider
          vertical
          class="mx-4"
        />

        <v-flex sm5>
          <vbt-table
            :headers="signaturesHeaders"
            :items="t.signatures"
            table-name="Signatures"
            show-paginator
          />
        </v-flex>
      </v-layout>

      <v-divider
        v-if="i < types.length - 1"
        class="my-4"
      />
    </v-layout>
  </vbt-content-box>
</template>

<script>
const machinesHeaders = Object.freeze([
  { text: 'Name', value: 'name' },
]);

const signaturesHeaders = Object.freeze([
  { text: 'Name', value: 'name' },
  { text: 'Sides Up', value: 'sidesUp' },
  { text: 'Singleton Slots', value: 'singletonSlots' },
]);

export default {
  name: 'DsbPressSheetsTemplateTypes',

  data() {
    return {
      machinesHeaders,
      signaturesHeaders,
    };
  },

  props: {
    types: {
      type: Array,
      required: true,
    },
    loadingError: {
      type: [Boolean, String, Object],
      default: false,
    },
    loading: Boolean,
  },
};
</script>
