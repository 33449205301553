<template>
  <v-layout column>
    <dsb-press-sheets-template-details
      :loading-error="loadingError"
      :loading="loading"
      :template="pressSheetTemplate"
    />

    <dsb-press-sheets-template-types
      :loading-error="loadingError"
      :loading="loading"
      :types="pressSheetTemplateTypes"
    />
  </v-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { useDataLoading } from '@mixins/factories';

import {
  namespace as PressSheetsTemplateNamespace,
  ActionTypes as PressSheetsTemplateActionTypes,
} from '../store.types';

import DsbPressSheetsTemplateDetails from '../_components/DsbPressSheetsTemplateDetails.vue';
import DsbPressSheetsTemplateTypes from '../_components/DsbPressSheetsTemplateTypes.vue';

const dataLoadingOptions = {
  getterName: PressSheetsTemplateActionTypes.GET_PRESS_SHEETS_TEMPLATE,
  parameterize: true,
  searchable: false,
  paged: false,
};

export default {
  name: 'DsbPressSheetsTemplate',

  components: {
    DsbPressSheetsTemplateDetails,
    DsbPressSheetsTemplateTypes,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
  ],

  computed: {
    ...mapState(PressSheetsTemplateNamespace, {
      pressSheetTemplate: ({ pressSheetsTemplate: item }) => item,
      pressSheetTemplateTypes: ({ pressSheetsTemplate: item }) => (item.pressSheetTypes || [])
        .map((t) => ({ ...t, machines: t.machines || [], signatures: t.signatures || [] })),
    }),
  },

  methods: {
    ...mapActions(PressSheetsTemplateNamespace, [
      PressSheetsTemplateActionTypes.GET_PRESS_SHEETS_TEMPLATE,
    ]),
  },
};
</script>
