<template>
  <vbt-content-box
    :no-content="noContent"
    :loading-error="loadingError"
    :loading="loading"
    title="Related Rules"
  >
    <template #toolbar>
      <dsb-order-item-force-build
        v-if="hadAdminRole && !noContent"
        :order-item-id="orderItemId"
        @force:build:success="$emit('build:success')"
      />
    </template>

    <vbt-table
      :headers="headers"
      :items="items"
      show-paginator
    >
      <template #id="{ item }">
        <router-link
          :to="{
            name: hadAdminRole ? 'ruleEdit' : 'ruleDetails',
            query: {
              suffix,
            },
            params: {
              id: item.id
            },
          }"
          target="_blank"
        >
          {{ item.id }}
        </router-link>
      </template>

      <template #quantity="{ item }">
        {{ `${item.minQuantity} - ${item.maxQuantity}` }}
      </template>

      <template #pressSheetTemplates="{ item }">
        {{ (item.pressSheetTemplates || []).join(', ') }}
      </template>

      <template #status="{ item }">
        <dsb-entity-badge
          v-for="status in (item.status || [])"
          class="mr-2"
          :key="genGUID(status)"
          :entity="status"
        />
      </template>
    </vbt-table>
  </vbt-content-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { genGUID } from '@helpers';

import { AuthNamespace, AppLocationsNamespace } from '@store/types';

import DsbOrderItemForceBuild from './DsbOrderItemForceBuild.vue';

const headers = Object.freeze([
  { text: 'ID', value: 'id' },
  { text: 'Quantity', value: 'quantity' },
  { text: 'Product Type', value: 'productType' },
  { text: 'Substrate', value: 'substrate' },
  { text: 'Press Sheet Templates', value: 'pressSheetTemplates' },
  { text: 'Hours Before Truck', value: 'hoursBeforeTruck' },
  { text: 'Status', value: 'status' },
]);

export default {
  name: 'DsbOrderItemRelatedRules',

  components: {
    DsbOrderItemForceBuild,
  },

  data() {
    return {
      headers,
    };
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    loadingError: {
      type: [Boolean, String, Object],
      default: false,
    },
    loading: Boolean,
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    ...mapGetters(AuthNamespace, ['hadAdminRole']),

    noContent() {
      return !this.items.length;
    },

    orderItemId() {
      return this.$route.params.id;
    },
  },

  methods: {
    genGUID,
  },
};
</script>
