<template>
  <vbt-dialog
    v-model="dialog"
    :disable-submit-btn="!idsArr.length"
    :title="title"
    width="700px"
    @submit="syncOrderItems"
    @close="resetForm"
  >
    <template #activator="{ on: onDialog }">
      <v-tooltip top>
        <template #activator="{ on: onTooltip }">
          <span v-on="onTooltip">
            <v-btn
              color="success"
              small
              icon
              v-on="onDialog"
            >
              <v-icon>
                sync
              </v-icon>
            </v-btn>
          </span>
        </template>
        Sync order items with ProFIT
      </v-tooltip>
    </template>

    <v-textarea
      v-model.trim="idsStr"
      placeholder="Enter orders ids separated by commas or other separators"
      class="mt-2 vbt-hide-form-control-details"
      label="Order IDs"
      no-resize
      outlined
      @keypress.enter="syncOrderItems"
    />
  </vbt-dialog>
</template>

<script>
import { mapState } from 'vuex';

import { generateArrayFromStringWithSeparators, wrapToLoadingFn } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

import { OrderItemsApiService } from '../_services/orderItems.api.service';

export default {
  name: 'DsbOrderItemsSync',

  props: {
    ids: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Sync order items with ProFIT',
    },
  },

  data() {
    return {
      idsStr: '',
      dialog: false,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    idsArr() {
      return generateArrayFromStringWithSeparators(this.idsStr);
    },
  },

  watch: {
    ids(newValue) {
      this.idsStr = this.idsStr ? `${this.idsStr}, ${newValue}` : newValue;
    },
  },

  methods: {
    syncOrderItems() {
      this.$VBlackerTheme.alert.warning({
        text: 'Sync order items?',
      }, () => this.wrapToLoadingFn({
        req: OrderItemsApiService.syncOrderItemsByIds.bind({}, {
          suffix: this.suffix,
          ids: this.idsArr,
        }),
        fullscreenLoadingSpinner: true,
        onSuccess: () => {
          this.dialog = false;
          this.$emit('sync:success');
          this.$VBlackerTheme.notification.success('Successfully synced');
        },
      }));
    },

    resetForm() {
      this.idsStr = this.ids || '';
    },

    wrapToLoadingFn,
  },
};
</script>
