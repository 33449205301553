<template>
  <vbt-content-box
    :loading-error="loadingError"
    :loading="loading"
    title="Details"
  >
    <v-layout align-center>
      <v-flex sm2>
        <strong>
          Outer ID:
        </strong>
        {{ template.outerId }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Name:
        </strong>
        {{ template.name }}
      </v-flex>

      <v-flex sm4>
        <strong>
          Version:
        </strong>
        {{ template.version }}
      </v-flex>
    </v-layout>
  </vbt-content-box>
</template>

<script>
export default {
  name: 'DsbPressSheetsTemplateDetails',

  props: {
    template: {
      type: Object,
      required: true,
    },
    loadingError: {
      type: [Boolean, String, Object],
      default: false,
    },
    loading: Boolean,
  },
};
</script>
