var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vbt-content-box',{attrs:{"no-content":_vm.noContent,"loading-error":_vm.loadingError,"loading":_vm.loading,"title":"Related Rules"},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [(_vm.hadAdminRole && !_vm.noContent)?_c('dsb-order-item-force-build',{attrs:{"order-item-id":_vm.orderItemId},on:{"force:build:success":function($event){return _vm.$emit('build:success')}}}):_vm._e()]},proxy:true}])},[_c('vbt-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"show-paginator":""},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: _vm.hadAdminRole ? 'ruleEdit' : 'ruleDetails',
          query: {
            suffix: _vm.suffix,
          },
          params: {
            id: item.id
          },
        },"target":"_blank"}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"quantity",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(((item.minQuantity) + " - " + (item.maxQuantity)))+" ")]}},{key:"pressSheetTemplates",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s((item.pressSheetTemplates || []).join(', '))+" ")]}},{key:"status",fn:function(ref){
        var item = ref.item;
return _vm._l(((item.status || [])),function(status){return _c('dsb-entity-badge',{key:_vm.genGUID(status),staticClass:"mr-2",attrs:{"entity":status}})})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }