<template>
  <v-layout column>
    <dsb-order-item-tags
      :loading-error="loadingError"
      :loading="loading"
      :tags="orderItem.tags || []"
      @sync:success="$_fetchData"
    />

    <dsb-order-item-details
      :loading-error="loadingError"
      :loading="loading"
      :item="orderItem"
      @sync:success="$_fetchData"
    />

    <dsb-order-item-signatures
      :loading-error="loadingError"
      :loading="loading"
      :items="orderItemSignatures"
    />

    <dsb-order-item-history
      :loading-error="loadingError"
      :loading="loading"
      :items="orderItemHistory"
    />

    <dsb-order-item-related-rules
      :loading-error="loadingError"
      :loading="loading"
      :items="orderItemRelatedRules"
      @build:success="$_fetchData"
    />
  </v-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { useDataLoading } from '@mixins/factories';

import { formatDisplayDate } from '@helpers';

import {
  namespace as OrderItemsNamespace,
  ActionTypes as OrderItemsActionTypes,
} from '../store.types';

import DsbOrderItemTags from '../_components/DsbOrderItemTags.vue';
import DsbOrderItemDetails from '../_components/DsbOrderItemDetails.vue';
import DsbOrderItemSignatures from '../_components/DsbOrderItemSignatures.vue';
import DsbOrderItemHistory from '../_components/DsbOrderItemHistory.vue';
import DsbOrderItemRelatedRules from '../_components/DsbOrderItemRelatedRules.vue';

const dataLoadingOptions = {
  getterName: OrderItemsActionTypes.GET_ORDER_ITEM,
  parameterize: true,
  searchable: false,
  paged: false,
};

export default {
  name: 'DsbOrderItem',

  components: {
    DsbOrderItemTags,
    DsbOrderItemDetails,
    DsbOrderItemSignatures,
    DsbOrderItemHistory,
    DsbOrderItemRelatedRules,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
  ],

  computed: {
    ...mapState(OrderItemsNamespace, {
      orderItem: ({ orderItem }) => orderItem,
      orderItemSignatures: ({ orderItem: { signatures: items } }) => items || [],
      orderItemHistory: ({ orderItem: { history: items } }) => items || [],
      orderItemRelatedRules: ({ orderItem: { relatedRules: items } }) => items || [],
    }),
  },

  methods: {
    ...mapActions(OrderItemsNamespace, [OrderItemsActionTypes.GET_ORDER_ITEM]),

    formatDisplayDate,
  },
};
</script>
