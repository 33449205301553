<template>
  <v-btn
    x-small
    color="warning"
    @click="runForceBuild"
  >
    Run force build
  </v-btn>
</template>

<script>
import { mapState } from 'vuex';

import { wrapToLoadingFn } from '@helpers';

import { AppLocationsNamespace } from '@store/types';

import { OrderItemsApiService } from '../_services/orderItems.api.service';

export default {
  name: 'DsbOrderItemForceBuild',

  props: {
    orderItemId: {
      type: [String, Number],
      required: true,
    },
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),
  },

  methods: {
    runForceBuild() {
      this.$VBlackerTheme.alert.warning({
        text: 'Run force build?',
      }, () => this.wrapToLoadingFn({
        req: OrderItemsApiService.forceBuildOrderItem.bind({}, {
          suffix: this.suffix,
          id: this.orderItemId,
        }),
        fullscreenLoadingSpinner: true,
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Successfully built');
          this.$emit('force:build:success');
        },
      }));
    },

    wrapToLoadingFn,
  },
};
</script>
